import React from 'react';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';

// styled
import Superscript from '../components/styled/Superscript';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';

class CodeOfConduct extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Code of conduct" />
        <Nav selected="/code-of-conduct" />
        <Header />
        <Section noPaddingTop>
          <Wrapper>
            <Superscript small center>
              Code of conduct
            </Superscript>
          </Wrapper>
        </Section>
        <Section
          noPaddingTop
          firstSquareDetail="left"
          secondSquareDetail="right"
        >
          <Wrapper>
            <p>
              <strong>Assert(js)</strong> is dedicated to providing a
              harassment-free conference experience for everyone, regardless of
              gender, gender identity and expression, sexual orientation,
              disability, physical appearance, body size, race, age or religion.
              We do not tolerate harassment of conference participants in any
              form. Sexual language and imagery is not appropriate for any
              conference venue, including talks. Conference participants
              violating these rules may be sanctioned or expelled from the
              conference without a refund at the discretion of the conference
              organizers.
            </p>
            <p>
              <strong>Anti-harassment</strong> Harassment includes, but is not
              limited to: 
              <ul>
                <li>Verbal comments that reinforce social structures of
                domination related to gender, gender identity and expression,
                sexual orientation, disability, physical appearance, body size,
                race, age, religion</li>
                <li>Sexual images in public spaces</li>
                <li>Deliberate intimidation, stalking, or following</li>
                <li>Harassing photography or recording</li>
                <li>Sustained disruption of talks or other events</li>
                <li>Inappropriate physical contact</li>
                <li>Unwelcome sexual attention</li>
                <li>Advocating for, or encouraging, any of tg he above behaviour</li>
              </ul>
            </p>
            <p>
              <strong>Enforcement</strong> Participants asked to stop any
              harassing behavior are expected to comply immediately. If a
              participant engages in harassing behaviour, event organisers
              retain the right to take any actions to keep the event a welcoming
              environment for all participants. This includes warning the
              offender or expulsion from the conference with no refund. Event
              organisers may take action to redress anything designed to, or
              with the clear impact of, disrupting the event or making the
              environment hostile for any participants. We expect participants
              to follow these rules at all event venues and event-related social
              activities. We think people should follow these rules outside
              event activities too!
            </p>
            <p>
              <strong>Reporting</strong> If someone makes you or anyone else
              feel unsafe or unwelcome, please report it as soon as possible.
              Conference staff can be identified by OK GROW! t-shirts.
              Harassment and other Code of Conduct violations reduce the value
              of our event for everyone. We want you to be happy at our event.
              People like you make our event a better place. You can make a
              report either personally or anonymously either by contacting event
              staff or emailing us at: <strong>assertjs@okgrow.com</strong> Our
              team will be happy to help you contact hotel/venue security, local
              law enforcement, local support services, provide escorts, or
              otherwise assist you to feel safe for the duration of the event.
              We value your attendance.
            </p>
          </Wrapper>
        </Section>

        <Footer />
      </Container>
    );
  }
}

export default CodeOfConduct;
